import { createAction } from '@reduxjs/toolkit';
import { createAction as createActionApi } from 'redux-api-middleware';
import resolveUrl from 'resolve-url';

import { Car, ApiMiddlewareError } from '../../cars/types';
import { TSeen } from '../types';
import type { TGetState } from '../../index';

import { config } from '../../../utils';

export const addOneSeen = createAction<TSeen>('addOneSeen');
export const setCarsSeenAll = createAction<TSeen[]>('setCarsSeenAll');
export const getCarsSeenRequest = createAction('getCarsSeenRequest');
export const getCarsSeenSuccess = createAction<Car[]>('getCarsSeenSuccess');
export const getCarsSeenError = createAction<ApiMiddlewareError>(
  'getCarsSeenError'
);

export function getCarsSeen() {
  return async (dispatch: Function, getState: TGetState) => {
    const { carsSeen } = getState();
    const vins = carsSeen.seen.map(({ vin }) => vin);

    const actionResponse = await dispatch(
      createActionApi({
        endpoint: resolveUrl(
          config.get('carStockApi'),
          './vehicles/',
          `?state=active&hidden=false&vin__in=${vins.join()}`
        ),
        method: 'GET',
        types: [
          getCarsSeenRequest.type,
          getCarsSeenSuccess.type,
          getCarsSeenError.type,
        ],
      })
    );

    return actionResponse;
  };
}

export default {
  addOneSeen,
  setCarsSeenAll,
  getCarsSeen,
  getCarsSeenRequest,
  getCarsSeenSuccess,
  getCarsSeenError,
};
