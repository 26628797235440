import { useEffect } from 'react';

import { useDispatch, useSelector } from '../hooks';
import { actions, selectors } from '../index';

export default (id: any) => {
  const car = useSelector(selectors.getCar);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(actions.getCarFromStoreOrApi(id));
    }

    return () => {
      dispatch(actions.clearCar());
    };
  }, [id]);

  return { car };
};
