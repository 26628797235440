import { createSelector } from '@reduxjs/toolkit';

import getData from '../getData';

/**
 * Возвращает для измененных range фильтров список названий
 */
const getChangedRangesFilters = createSelector(
  getData,
  ({ filtersState, ranges }) => {
    const result: string[] = [];

    if (!filtersState || !ranges) {
      return result;
    }

    Object.keys(ranges).forEach((key) => {
      if (filtersState[key]?.value) {
        const rangeValue = ranges[key];
        const filterValue = filtersState[key]?.value;

        if (
          filterValue[0] !== rangeValue.min ||
          filterValue[1] !== rangeValue.max
        ) {
          result.push(key);
        }
      }
    });

    return result;
  }
);

export default getChangedRangesFilters;
