import { config } from '.';

function resolveName(name: string) {
  const prefix = config.get('storagePrefix') || 'connect';
  return `__${prefix}__${name}`;
}

function get(name: string) {
  const value = localStorage.getItem(resolveName(name));
  return value && JSON.parse(value);
}

function set(name: string, value: any) {
  localStorage.setItem(resolveName(name), JSON.stringify(value));
}

export default {
  get,
  set,
};
