import { createAction } from '@reduxjs/toolkit';
import { RSAA, RSAAAction } from 'redux-api-middleware';
import queryStringify from 'qs/lib/stringify';
import resolveUrl from 'resolve-url';

import { Cars, ApiMiddlewareError } from '../../cars/types';

import { config } from '../../../utils';

/**
 * Получение похожих авто c API
 */

export const getCarsRelatedRequest = createAction('getCarsRelatedRequest');
export const getCarsRelatedSuccess = createAction<Cars>(
  'getCarsRelatedSuccess'
);
export const getCarsRelatedError = createAction<ApiMiddlewareError>(
  'getCarsRelatedError'
);

export const getCarsRelated = (opts: { [key: string]: any }): RSAAAction => {
  return {
    [RSAA]: {
      endpoint: resolveUrl(
        config.get('carStockApi'),
        `./vehicles/`,
        `?${queryStringify({
          ...opts,
        })}`
      ),
      method: 'GET',
      types: [
        getCarsRelatedRequest.type,
        getCarsRelatedSuccess.type,
        getCarsRelatedError.type,
      ],
    },
  };
};
