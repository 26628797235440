import type { Store } from '../store';
import { observeStore, storage } from '../utils';
import { favouritesSelectors, favouritesActions } from '../store/favourites';

/**
 * Сохрарение в стор
 */
function saveToStore(
  favourites: ReturnType<typeof favouritesSelectors.favouritesAll>
) {
  storage.set('favourites', favourites);
}

/**
 * Наблюдение за изменениями в избранном
 */
export default function observeFavourites(store: Store) {

  /* Инициализация избранного */
  const savedFavourites = storage.get('favourites');
  savedFavourites && store.dispatch(favouritesActions.setAllFavourites(savedFavourites));

  /* Инициализация наблюдения */
  observeStore(store, favouritesSelectors.favouritesAll, saveToStore);
}
