import { createSelector } from '@reduxjs/toolkit';
import getLoading from '../getLoading';

/**
 * Возвращает loading для кейса "Получение параметров фильтров"
 */
const getFiltersLoading = createSelector(getLoading, loading =>
  Boolean(loading.postQueries || loading.getQueriesValues)
);

export default getFiltersLoading;
