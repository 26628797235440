import axios from 'axios';

const carStockApi = axios.create();

type TFetchCarsByVins = {
  vins: string[];
  sort?: string;
  perPage?: number;
};

export const fetchCarsByVins = ({
  vins,
  sort = 'price',
  perPage = 1000,
}: TFetchCarsByVins) => {
  return carStockApi.get('/vehicles/', {
    params: {
      vin__in: `${vins.join()},`, // TODO: убрать лишний знак ',' - когда починят карсток.
      sort,
      per_page: perPage,
      state: 'active',
      hidden: false,
    },
  });
};

export default carStockApi;
