import { createSelector } from '@reduxjs/toolkit';
import getData from '../getData';

/**
 * Возвращает общее кол-во страниц для текущего queries id
 */
const getPageCount = createSelector(getData, data => {
  if (!data.count || !data.perPage) return;

  return Math.ceil(data.count / data.perPage);
});

export default getPageCount;
