import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  PayloadAction,
  SerializedError,
  EntityState,
} from '@reduxjs/toolkit';

import type { State, TGetState } from '../index';
import type { Car } from '../cars/types';
import { config } from '../../utils';
import * as api from '../../api';
import intersectionBy from 'lodash/intersectionBy';

type TFavourite = {
  id: string;
  vin: string;
};

const favouritesAdapter = createEntityAdapter<TFavourite>();
const carsAdapter = createEntityAdapter<Car>();

/**
 * Selectors
 */
const favouritesSelectors = favouritesAdapter.getSelectors<State>(
  (state) => state.favourites.favourites
);

const carsSelectors = carsAdapter.getSelectors<State>(
  (state) => state.favourites.cars
);

const selectors = {
  favouritesAll: favouritesSelectors.selectAll,
  favouritesById: favouritesSelectors.selectById,
  favouritesEntities: favouritesSelectors.selectEntities,
  favouritesIds: favouritesSelectors.selectIds,
  favouritesTotal: favouritesSelectors.selectTotal,
  carsAll: carsSelectors.selectAll,
  carsById: carsSelectors.selectById,
  carsEntities: carsSelectors.selectEntities,
  carsIds: carsSelectors.selectIds,
  carsTotal: carsSelectors.selectTotal,
  fetchFavouriteCars: (state: State) => ({
    loading: state.favourites.loading,
    error: state.favourites.error,
    sort: state.favourites.sort,
    perPage: state.favourites.perPage,
  }),
};

/**
 * Async actions
 */
const fetchFavouriteCars = createAsyncThunk<
  Car[],
  { forceFetch?: boolean } | undefined,
  { state: State }
>(
  'favourites/fetchCarsByVins',
  async ({ forceFetch = false } = {}, thunkAPI) => {
    const favourites = selectors.favouritesAll(thunkAPI.getState());
    const favouritesVins = favourites.map((f) => f.vin);
    const loadedCars = selectors.carsAll(thunkAPI.getState());

    const fetchFavouriteCarsState = selectors.fetchFavouriteCars(
      thunkAPI.getState()
    );

    const intersection = intersectionBy(loadedCars, favourites, 'id');

    // Все избранные автомобили загружены
    const hasCars =
      intersection.length === loadedCars.length &&
      intersection.length === favourites.length;

    const needFetch = !hasCars || forceFetch;

    let response = { data: loadedCars };

    if (needFetch) {
      response = await api.fetchCarsByVins({
        vins: favouritesVins,
        sort: fetchFavouriteCarsState.sort,
        perPage: fetchFavouriteCarsState.perPage,
      });
    }

    return response.data;
  }
);

type TFavouritesState = {
  favourites: EntityState<TFavourite>;
  cars: EntityState<Car>;
  loading: boolean;
  error: SerializedError | undefined;
  sort: string | 'price' | '-price' | 'state_active_at' | '-state_active_at';
  perPage: number;
};

const favourites = config.get('favourites');

export const initialState: TFavouritesState = {
  favourites: favouritesAdapter.getInitialState(),
  cars: carsAdapter.getInitialState(),
  loading: false,
  error: undefined,
  sort: favourites.defaultSort,
  perPage: favourites.defaultPerPage,
};

const favouritesSlice = createSlice({
  name: 'favourites',
  initialState,
  reducers: {
    addOneFavourite(state, action: PayloadAction<TFavourite>) {
      favouritesAdapter.addOne(state.favourites, action.payload);
    },
    removeOneFavourite(state, action: PayloadAction<TFavourite['id']>) {
      favouritesAdapter.removeOne(state.favourites, action.payload);
    },
    setAllFavourites(state, action: PayloadAction<TFavourite[]>) {
      favouritesAdapter.setAll(state.favourites, action.payload);
    },
    setAllCars(state, action: PayloadAction<Car[]>) {
      carsAdapter.setAll(state.cars, action.payload);
    },
    setSort(state, action: PayloadAction<string>) {
      state.sort = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFavouriteCars.pending, (state) => {
      state.error = undefined;
      state.loading = true;
    });

    builder.addCase(fetchFavouriteCars.fulfilled, (state, action) => {
      const carsData = action.payload;
      const fetchedVinsIds = carsData.map(({ id, vin }) => ({ id, vin }));

      carsAdapter.setAll(state.cars, carsData);
      state.loading = false;

      // чтобы скрытые/удаленные/архивные авто не оставались в Избранном, только авто со статусом "Опубликован"
      if (fetchedVinsIds.length !== state.favourites.ids.length) {
        favouritesAdapter.setAll(state.favourites, fetchedVinsIds);
      }
    });

    builder.addCase(fetchFavouriteCars.rejected, (state, action) => {
      state.error = action.error;
      state.loading = false;
    });
  },
});

const { reducer } = favouritesSlice;

/**
 * Thunk actions
 */
const switchFavourite = (car: Car) => (
  dispatch: Function,
  getState: TGetState
) => {
  const favourite = selectors.favouritesById(getState(), car.id);

  if (favourite) {
    dispatch(favouritesSlice.actions.removeOneFavourite(car.id));
  } else {
    dispatch(
      favouritesSlice.actions.addOneFavourite({ id: car.id, vin: car.vin })
    );
  }
};

const setSortAndFetchCars = (sort: string) => (dispatch: Function) => {
  dispatch(favouritesSlice.actions.setSort(sort));
  dispatch(fetchFavouriteCars({ forceFetch: true }));
};

const actions = {
  ...favouritesSlice.actions,
  switchFavourite,
  fetchFavouriteCars,
  setSortAndFetchCars,
};

export {
  reducer as default,
  actions as favouritesActions,
  selectors as favouritesSelectors,
};
