import {
  createDispatchHook,
  createSelectorHook,
  createStoreHook,
  TypedUseSelectorHook,
} from 'react-redux';

import CarstockContext from './context';
import { State } from './store';

export const useDispatch = createDispatchHook(CarstockContext);
export const useStore = createStoreHook(CarstockContext);

export const useSelector: TypedUseSelectorHook<State> = createSelectorHook(
  CarstockContext
);
