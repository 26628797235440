import { createReducer as toolkitCreateReducer } from '@reduxjs/toolkit';

import {
  addOneSeen,
  setCarsSeenAll,
  getCarsSeenRequest,
  getCarsSeenSuccess,
  getCarsSeenError,
} from './actions';
import { config } from '../../utils';

import { CarsSeenState } from './types';

export default function createReducer() {
  const { carsSeen } = config.getConfigObject();

  /**
   * По умолчанию показываем (maxCountCarsSeen) 3 авто.
   * Добавляем на +1 больше,
   * т.к. текущее авто не показываем (если оно попало в store)
   */
  const initialState: CarsSeenState = {
    data: [],
    seen: [],
    loading: false,
    maxCountCarsSeen: (carsSeen?.count || 3) + 1,
  };

  return toolkitCreateReducer<CarsSeenState>(initialState, (builder) => {
    builder.addCase(addOneSeen, (state, action) => {
      const { id } = action.payload;
      const isCarExist = state.seen.find((car) => car.id === id);

      /**
       * Не записывать больше авто если превышает определенное кол-во
       * и уже существующие авто в массиве
       */
      if (isCarExist) {
        // sort car exist на первое место
        state.seen.sort((a, b) => {
          if (a.id === id) {
            return -1;
          }
          if (b.id === id) {
            return 1;
          }
          return 0;
        });
      } else {
        if (state.seen.length < state.maxCountCarsSeen) {
          state.seen.unshift(action.payload);
        } else {
          // добавить в начало и уменьшить массив на один елемент
          state.seen = [
            action.payload,
            ...state.seen.slice(0, state.maxCountCarsSeen - 1),
          ];
        }
      }
    });

    builder.addCase(setCarsSeenAll, (state, action) => {
      state.seen = action.payload;
    });

    builder.addCase(getCarsSeenRequest, (state) => {
      state.error = undefined;
      state.loading = true;
    });

    builder.addCase(getCarsSeenSuccess, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });

    builder.addCase(getCarsSeenError, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  });
}
