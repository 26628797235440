import { createSelector } from '@reduxjs/toolkit';
import { config } from '../../utils';
import getCarImages from '../getCarImages';

/**
 * Получени первой превьюшки для указанного автомобиля
 */
const getCarFirstThumb = createSelector(
  getCarImages,
  car => {
    if (!car) return;
    const { brand, name } = car;
    return `${brand}${name ? ` ${name}` : ''}`;
  },
  (images, alt) => {
    const firstImage = images?.[0];
    const url = firstImage && `${firstImage.url}_${config.get('thumbId')}`;

    return { url, alt };
  }
);

export default getCarFirstThumb;
