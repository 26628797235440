import { FilterValue, FilterValueRange } from '../../store/cars/types';

/**
 * Определяет является ли фильтр типом FilterValueRange
 * @param filter Значение фильтра тип которого нужно определить
 */
export default function isFilterValueRange(
  filter: FilterValue
): filter is FilterValueRange {
  return 'minValue' in filter && 'maxValue' in filter;
}
