import { createSelector } from '@reduxjs/toolkit';

import getCar from '../getCar';
import getCarsSeen from '../getCarsSeen';

const getCarsSeenForCurrentCar = createSelector(
  getCar,
  getCarsSeen,
  (car, { maxCountCarsSeen, data }) => {
    const filteredCars = data.filter((item) => item.id !== car?.id);
    return filteredCars.slice(0, maxCountCarsSeen - 1);
  }
);

export default getCarsSeenForCurrentCar;
