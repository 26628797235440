import { Middleware } from 'redux';
import { State } from '../store';

import { abortMap, UiHelper, config } from '../utils';

import {
  postQueriesRequest,
  postQueriesSuccess,
  getCarsByQueriesId,
  getQueriesSummary,
  getQueriesValues,
} from '../store/cars/actions';

const getCarsByQueriesIdMiddleware: Middleware<
  {},
  State
> = store => next => action => {
  // Отменяем предыдущие связанные запросы т.к postQueriesSuccess создаст новые
  if (postQueriesRequest.match(action)) {
    const {
      cars: { loading },
    } = store.getState();

    abortMap.abort(loading.getCarsByQueriesId);
    abortMap.abort(loading.getQueriesSummary);
    abortMap.abort(loading.getQueriesValues);
  }

  // Инициализируем связанные запросы
  if (postQueriesSuccess.match(action)) {
    const { history } = config.getConfigObject();
    const { id: queriesId } = action.payload;

    const {
      cars: {
        data: { filtersValues, sort, perPage },
      },
    } = store.getState();

    const uiHelper = new UiHelper({});

    const linkedQueries = function() {
      store.dispatch(getQueriesSummary(queriesId));
      store.dispatch(getQueriesValues(queriesId, uiHelper.getFiltersPaths()));
      store.dispatch(getCarsByQueriesId(queriesId, perPage, 0, sort));
    };

    // Если есть history считаем что включен функционал "фильтры в URL"
    if (history) {
      // Если filtersValues есть значит не первый запрос
      if (filtersValues) {
        linkedQueries();
      }
      // Если filtersValues === undefined есть значит первый запрос
      // в этом случае повторный postQueries инициализируется в urlMiddleware
      else {
        store.dispatch(getQueriesValues(queriesId, uiHelper.getFiltersPaths()));
      }
    } else {
      linkedQueries();
    }
  }

  next(action);
};

export default getCarsByQueriesIdMiddleware;
