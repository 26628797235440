import { FilterValue, FilterValueDefault } from '../../store/cars/types';

/**
 * Определяет является ли фильтр типом FilterValueDefault
 * @param filter Значение фильтра тип которого нужно определить
 */
export default function isFiltersValueDefault(
  filter: FilterValue
): filter is FilterValueDefault {
  return 'values' in filter;
}
