import qs, { IStringifyOptions, IParseOptions } from 'qs';

export const qsConfig: {
  parse: IParseOptions;
  stringify: IStringifyOptions;
} = {
  parse: {
    ignoreQueryPrefix: true,
    comma: true,
    strictNullHandling: true,
  },
  stringify: {
    addQueryPrefix: true,
    arrayFormat: 'comma',
    strictNullHandling: true,
  },
} as const;

type TParseReturn = {
  [K: string]: any;
};

export function parse(value: string, options?: IParseOptions): TParseReturn {
  return qs.parse(value, { ...qsConfig.parse, ...options });
}

export function stringify(
  value: { [key: string]: any },
  options?: IStringifyOptions
) {
  return qs.stringify(value, { ...qsConfig.stringify, ...options });
}
