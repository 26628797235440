import getData from '../getData';
import { createSelector } from '@reduxjs/toolkit';

/**
 * Возвращает параметры необходимые для экшена getCarsByQueriesId
 */
const getResultParams = createSelector(
  getData,
  ({ page, perPage, queriesId, sort }) => ({
    page,
    perPage,
    queriesId,
    sort,
  })
);

export default getResultParams;
