import { useEffect } from 'react';

import { useDispatch, useSelector } from '../hooks';
import { actions, selectors } from '../index';
import { Car } from '../store/cars/types';

export default (car?: Car) => {
  const carsSeenForCurrentCar = useSelector(selectors.getCarsSeenForCurrentCar);
  const dispatch = useDispatch();

  /**
   * Первым диспатчем забираем список просмотренных авто
   * Только потом обновляем список
   */
  useEffect(() => {
    dispatch(actions.carsSeen.getCarsSeen());

    if (car) {
      const { id, vin } = car;
      dispatch(actions.carsSeen.addOneSeen({ id, vin }));
    }
  }, [car]);

  return { carsSeenForCurrentCar };
};
