import { Car } from '../../store/cars/types';

/**
 * Получение списка изображений для указаного автомобиля
 * @param car Объект автомобиль
 */
const getCarImages = (car?: Car) => {
  const images = car?.images?.filter(({ type }) => type === 'image');

  return images;
};

export default getCarImages;
