import React, { useMemo, ReactNode } from 'react';
import debounce from 'debounce';
import actions from '../store/cars/actions';

import {
  Fields,
  FilterValue,
  FilterValueDefaultValuesItem,
  Ranges,
} from '../store/cars/types';

import { config, urlQueryString, typeGuards } from '.';

export type FilterConfig<T = any> = T & {
  /** Название (ключ) фильтра для экшена postQueries */
  id: string;
  /** Путь (ключ) по которому можно получить возможные значения фильтра для экшена getQueriesValues */
  path?: string;
  /** Функция принимающая на вход значение фильтра от поля формы возвращающая его значение, пригодное для экшена postQueries */
  prepare?: Function;
  /** Значение фильтра для экшена postQueries в случае, если реальное текущее значение undefined */
  defaultVal?: any;
};

export type FiltersConfig<T = any> = {
  [K in keyof T]: FilterConfig<T[K]>;
};

export type PatchedFilterConfig<T = any> = FilterConfig<T> & {
  /** Название (ключ) для использования в качестве имени поля формы (БЕЗ ТОЧЕК) в связи с
   * кривой поддержкой вложеных полей (https://github.com/ant-design/ant-design/issues/7228)
   */
  name: string;
};

export type PatchedFiltersConfig<T = any> = {
  [K in keyof T]: PatchedFilterConfig<T[K]>;
};

export class UiHelper {
  /** Конфигурация фильтров */
  filtersConfig: PatchedFiltersConfig;

  /** См. метод createDebounceOnChange */
  debounceInterval: number = 1000;

  /** Redux dispatch with carstock context */
  dispatch?: (...args: any[]) => any;

  /** Возможные значения фильтров полученные от carstock API */
  filters?: FilterValue[];

  /** @see https://github.com/react-component/form#wrappedcomponent-reactcomponent--reactcomponent */
  form?: {
    [key: string]: Function;
  };

  constructor({
    filtersConfig,
    filters,
    form,
    debounceInterval,
    dispatch,
  }: {
    filtersConfig?: PatchedFiltersConfig;
    filters?: FilterValue[];
    form?: {
      [key: string]: Function;
    };
    debounceInterval?: number;
    dispatch?: (...args: any[]) => any;
  }) {
    this.dispatch = dispatch;
    this.filtersConfig = filtersConfig ?? config.get('filtersConfig');
    this.filters = filters;
    this.form = form;

    if (debounceInterval) {
      this.debounceInterval = debounceInterval;
    }
  }

  /**
   * Возвращает массив из path`ов
   */
  getFiltersPaths = () => {
    const paths = Object.keys(this.filtersConfig).reduce<string[]>(
      (acc, name) => {
        const path = this.filtersConfig[name].path;

        if (path) {
          acc.push(path);
        }
        return acc;
      },
      []
    );

    return paths;
  };

  /**
   * Возвращает массив из id`шников
   */
  getFiltersIds = () => {
    const ids = Object.keys(this.filtersConfig).reduce<string[]>(
      (acc, name) => {
        const id = this.filtersConfig[name].id;
        acc.push(id);
        return acc;
      },
      []
    );

    return ids;
  };

  /**
   * Поиск возможных значений определённого фильтра
   * @param name название фильтра на пример specification.class или year
   */
  findFilter = (name: string) => {
    const filter = this.filters?.find(item => item.name === name);
    return filter;
  };

  static defaultFilterMakeTitle(title: string, count: number) {
    return (
      <>
        <span className="select-dropdown-menu-item-value">{title}</span>
        <span className="select-dropdown-menu-item-count">({count})</span>
      </>
    );
  }

  /**
   * Возвращает адаптированное под UI компонент Select значение фильтра
   */
  getDefaultFilter = (
    name?: string,
    config?: {
      makeTitle?: (value: FilterValueDefaultValuesItem) => ReactNode;
    }
  ) => {
    if (!this.filters || !name) return [];

    const filter = this.findFilter(name);

    if (!filter) return [];

    if (typeGuards.isFilterValueDefault(filter)) {
      const resolve = filter.values.map(value => {
        const title = config?.makeTitle
          ? config?.makeTitle(value)
          : UiHelper.defaultFilterMakeTitle(value.value, value.count);

        return {
          value: value.id,
          title,
        };
      });

      return resolve;
    }

    return [];
  };

  /**
   * Возвращает адаптированное под UI компонент RangeSlider значение фильтра
   */
  getRangeFilter = (name?: string) => {
    if (!this.filters || !name) return { min: 0, max: 0 };

    const filter = this.findFilter(name);

    if (!filter) return { min: 0, max: 0 };

    if (typeGuards.isFilterValueRange(filter)) {
      return {
        min: filter.minValue,
        max: filter.maxValue,
      };
    }

    return { min: 0, max: 0 };
  };

  /**
   * Поиск конфигурации определённого фильтра по path
   */
  findFilterConfigByPath = (path: string) => {
    const filterName = Object.keys(this.filtersConfig).find(name => {
      return this.filtersConfig[name].path === path;
    });

    if (!filterName) return;

    // eslint-disable-next-line consistent-return
    return this.filtersConfig[filterName];
  };

  /**
   * Отправляет запрос на получение новых данных
   * @returns {Promise}
   */
  onChange = (name: string, value: any) => {
    const fields = this.getAllFields();
    const filterConfig = this.filtersConfig[name];

    if (!filterConfig) return;

    const resolvedValue = UiHelper.resolveFieldValue(filterConfig, value);

    // eslint-disable-next-line consistent-return
    return this.dispatch?.(
      actions.postQueries({
        ...fields,
        [filterConfig.id]: resolvedValue,
      })
    );
  };

  /**
   * Создает экземпляр memo onChange адапрированный для схемы value/onChange
   * для переданного списка фильтров
   */
  createOnChange = (filters: PatchedFilterConfig[]) => {
    const handlers = useMemo(
      () =>
        filters.map(filter => (value: any) =>
          this.onChange(filter.name, value)
        ),
      []
    );

    return handlers;
  };

  /**
   * Создает экземпляр memo debouce onChange адапрированный для схемы value/onChange
   * для переданного списка фильтров
   */
  createDebounceOnChange = (filters: PatchedFilterConfig[]) => {
    const debounceHandlers = useMemo(
      () =>
        filters.map(filter =>
          debounce(
            (value: any) => this.onChange(filter.name, value),
            this.debounceInterval
          )
        ),
      []
    );

    return debounceHandlers;
  };

  /**
   * Возвращает kv объект со значениями всех фильтров подготовленными
   * для отправки в carstock API
   */
  getAllFields = () => {
    const fields = Object.keys(this.filtersConfig).reduce<{
      [key: string]: any;
    }>((acc, field) => {
      const filterConfig = this.filtersConfig[field];

      if (!filterConfig) return acc;

      const value = this.form?.getFieldValue(filterConfig.name);
      const resolvedValue = UiHelper.resolveFieldValue(filterConfig, value);

      if (resolvedValue !== undefined) {
        acc[filterConfig.id] = resolvedValue;
      }

      return acc;
    }, {});

    return fields;
  };

  /**
   * Возвращает kv объект со значениями фильтров имеющих дефолтное значение
   */
  getDefaultFilters = () => {
    const defaultFilters = Object.keys(this.filtersConfig).reduce<{
      [key: string]: any;
    }>((acc, item) => {
      if (this.filtersConfig[item].defaultVal !== undefined) {
        acc[this.filtersConfig[item].id] = this.filtersConfig[item].defaultVal;
      }

      return acc;
    }, {});

    return defaultFilters;
  };

  /**
   * Возвращает подготовленное для API carstocka значение определённого фильтра
   */
  static resolveFieldValue(conf: PatchedFilterConfig, value: any) {
    const { prepare, defaultVal } = conf;
    let resolvedValue;

    // Сравниваем с undefined т.к. null возможное значение фильтра в карстоке
    if (value !== undefined) {
      resolvedValue = prepare ? prepare(value) : value;
    } else if (defaultVal !== undefined) {
      resolvedValue = defaultVal;
    }

    return resolvedValue;
  }

  /**
   * Получает значения range фильтров из запроса values
   */
  getRangesFilters: () => Ranges | undefined = () => {
    const ranges = this.filters?.reduce<Ranges>((acc, filterValue) => {
      if (typeGuards.isFilterValueRange(filterValue)) {
        const filterConf = this.findFilterConfigByPath(filterValue.name);

        if (filterConf?.id) {
          acc[filterConf.id] = {
            min: filterValue.minValue,
            max: filterValue.maxValue,
          };
        }
      }

      return acc;
    }, {});

    return ranges;
  };

  /**
   * Получает значения range фильтров из запроса values
   */
  getRangesValues: () => Fields | undefined = () => {
    const rangesFilters = this.getRangesFilters();

    if (rangesFilters) {
      const rangesValues: Fields = Object.keys(rangesFilters).reduce<Fields>(
        (acc, name) => {
          acc[name] = {
            value: [rangesFilters[name].min, rangesFilters[name].max],
          };

          return acc;
        },
        {}
      );

      return rangesValues;
    }
  };

  /**
   * Сброс фильтров
   */
  resetFilters = async () => {
    await this.initFilters();
    this.dispatch?.(actions.filtersReset());
  };

  /**
   * Инициализация фильтров с учётом параметров в адресной строке
   */
  initFilters = async () => {
    await this.dispatch?.(actions.postQueries(this.getDefaultFilters()));
  };

  /**
   * Создаёт конфиг пригодный для работы с rc-form https://github.com/ant-design/ant-design/issues/7228
   */
  static patchFiltersConfig = <T extends FiltersConfig<T>>(conf: T) => {
    const patchedConfig = (Object.keys(conf) as Array<keyof T>).reduce<
      PatchedFiltersConfig<T>
    >((acc, name) => {
      /* TODO: разобраться как сделать без "as any" */
      (acc[name] as any) = {
        ...conf[name],
        name,
      };

      return acc;
    }, {} as PatchedFiltersConfig<any>);

    return patchedConfig;
  };
}
