import { createSelector } from '@reduxjs/toolkit';
import getData from '../getData';

/**
 * Определяет загружены ли все автомобили для текущего queries id
 */
const getIsAllCarsLoaded = createSelector(
  getData,
  ({ cars, count }) => cars?.length === count
);

export default getIsAllCarsLoaded;
