import React, { FC } from 'react';
import { Provider } from 'react-redux';
import config, { Config } from './utils/config';
import createStore from './store';
import CarstockContext from './context';
import api from './api';

let store: ReturnType<typeof createStore>;

export function getStore() {
  return store;
}

/**
 * Возвращает CarstockProvider компонент
 * @param configuration Конфигурация
 */
function createCarstockProvider(configuration?: Partial<Config>) {
  configuration && config.set(configuration);
  api.defaults.baseURL = configuration?.carStockApi;
  store = createStore();

  const CarstockProvider: FC = ({ children }) => (
    <Provider store={store} context={CarstockContext}>
      {children}
    </Provider>
  );

  return CarstockProvider;
}

export default createCarstockProvider;
