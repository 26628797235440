import config from './config';
import qs from 'qs';
import * as queryString from './queryString';

function get() {
  const { history } = config.getConfigObject();
  return history
    ? qs.parse(history.location.search, queryString.qsConfig.parse)
    : {};
}

function set(query: { [key: string]: any }) {
  const { history } = config.getConfigObject();
  history?.replace(qs.stringify(query, queryString.qsConfig.stringify));
}

function merge(query: { [key: string]: any }) {
  const current = get();
  set({ ...current, ...query });
}

export default {
  get,
  set,
  merge,
  parse: queryString.parse,
  stringify: queryString.stringify,
};
