const controllers: {
  [key: string]: AbortController;
} = {};

function create() {
  const controller = new AbortController();
  const id = Math.random().toString();
  controllers[id] = controller;

  return {
    id,
    signal: controller.signal,
  };
}

function abort(id?: string) {
  if (id) {
    controllers[id]?.abort();
    delete controllers[id];
  }
}

function clean(id?: string) {
  id && delete controllers[id];
}

export default {
  create,
  abort,
  clean,
};
