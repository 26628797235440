import {
  configureStore,
  getDefaultMiddleware,
  Store as ReduxStore,
} from '@reduxjs/toolkit';
import { apiMiddleware } from 'redux-api-middleware';
import merge from 'lodash/merge';
import {
  urlMiddleware,
  postQueriesMiddleware,
  getCarsRelatedMiddleware,
} from '../middleware';
import { CarsState } from './cars/types';
import { CarsRelatedState } from './carsRelated/types';
import { CarsSeenState } from './carsSeen/types';
import { config } from '../utils';

/**
 * Import Redusers
 */
import createCarsReducer from './cars/reducer';
import createCarsRelatedReducer from './carsRelated/reducer';
import createCarsSeenReducer from './carsSeen/reducer';
import favouritesReducer, {
  initialState as favouritesInitialState,
} from './favourites';

/**
 * Impot Subscribers
 */
import {
  favourites as favouritesSubscriber,
  carsSeen as carsSeenSubscriber,
} from '../subscribes';

function createStore() {
  const conf = config.getConfigObject();

  const middleware = [
    ...getDefaultMiddleware({
      immutableCheck: true,
      serializableCheck: false,
      thunk: true,
    }),
    apiMiddleware,
    ...(conf.isBrowser && conf.history ? [urlMiddleware] : []),
    ...(conf.isBrowser ? [postQueriesMiddleware] : []),
    ...(conf.isBrowser ? [getCarsRelatedMiddleware] : []),
  ];

  const store = configureStore({
    reducer: {
      cars: createCarsReducer(),
      carsRelated: createCarsRelatedReducer(),
      carsSeen: createCarsSeenReducer(),
      favourites: favouritesReducer,
    },
    middleware,
    preloadedState: {
      favourites: merge(favouritesInitialState, {
        perPage: conf?.favourites?.defaultPerPage,
        sort: conf?.favourites?.defaultSort,
      }),
    },
  });

  /**
   * Init subscribers
   */
  conf.isBrowser && favouritesSubscriber(store);
  conf.isBrowser && carsSeenSubscriber(store);

  return store;
}

export default createStore;

export type State = {
  cars: CarsState;
  carsRelated: CarsRelatedState;
  carsSeen: CarsSeenState;
  favourites: ReturnType<typeof favouritesReducer>;
};

export type Store = ReduxStore<State>;

export type TGetState = Store['getState'];
