import getData from '../getData';
import { createSelector } from '@reduxjs/toolkit';
import { Fields, CarsState } from '../../store/cars/types';

const getRangesAsFilterState = ({ data: { ranges } }: CarsState) => {
  if (!ranges) return;

  const rangesAsFilterState = Object.keys(ranges).reduce<Fields>(
    (acc, name) => {
      acc[name] = { value: [ranges[name].min, ranges[name].max] };
      return acc;
    },
    {}
  );

  return rangesAsFilterState;
};

export default getRangesAsFilterState;
