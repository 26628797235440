import type { Store } from '../store';
import { observeStore, storage } from '../utils';
import { getCarsSeen as getCarsSeenSelector } from '../selectors';
import { setCarsSeenAll as setCarsSeenAllAction } from '../store/carsSeen/actions';

function saveToStore(carsSeen: ReturnType<typeof getCarsSeenSelector>) {
  storage.set('carsSeen', carsSeen.seen);
}

export default function observeCarsSeen(store: Store) {
  const savedCarsSeen = storage.get('carsSeen');

  if (savedCarsSeen) {
    store.dispatch(setCarsSeenAllAction(savedCarsSeen));
  }

  observeStore(store, getCarsSeenSelector, saveToStore);
}
