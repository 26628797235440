import { createReducer as toolkitCreateReducer } from '@reduxjs/toolkit';

import {
  getCarsRelatedRequest,
  getCarsRelatedSuccess,
  getCarsRelatedError,
} from './actions';

import { CarsRelatedState } from './types';

export default function createReducer() {
  const initialState: CarsRelatedState = {
    data: {},
    loading: false,
  };

  return toolkitCreateReducer<CarsRelatedState>(initialState, builder => {
    /**
     * Получение похожих авто
     */
    builder.addCase(getCarsRelatedRequest, state => {
      state.error = undefined;
      state.loading = true;
    });

    builder.addCase(getCarsRelatedSuccess, (state, action) => {
      state.data.carsRelated = action.payload;
      state.loading = false;
    });

    builder.addCase(getCarsRelatedError, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  });
}
