import { Store } from 'redux';
import isEqual from 'lodash/isEqual';

export default function observeStore<S, V = any>(
  store: Store<S>,
  select: (store: S) => V,
  onChange: (value: V) => void
) {
  let currentState: V;

  function handleChange() {
    let nextState = select(store.getState());
    if (!isEqual(nextState, currentState)) {
      currentState = nextState;
      onChange(currentState);
    }
  }

  let unsubscribe = store.subscribe(handleChange);
  handleChange();
  return unsubscribe;
}
