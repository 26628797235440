import { createSelector } from '@reduxjs/toolkit';
import getLoading from '../getLoading';

/**
 * Возвращает общий loading для кейса "Загрузка списка авто"
 */
const getCarsLoading = createSelector(getLoading, loading =>
  Boolean(
    loading.postQueries ||
      loading.getCarsByQueriesId ||
      loading.getQueriesSummary ||
      loading.getQueriesValues
  )
);

export default getCarsLoading;
