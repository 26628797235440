import { Middleware } from 'redux';
import { Fields } from '../store/cars/types';
import { State } from '../store';
import { urlQueryString, config, typeGuards, UiHelper } from '../utils';

import {
  getCarsByQueriesIdRequest,
  getQueriesValuesSuccess,
  filtersUpdate,
  postQueries,
  filtersReset,
} from '../store/cars/actions';

import { getResultParams } from '../selectors';

const urlMiddleware: Middleware<{}, State> = (store) => (next) => (action) => {
  // Пробрасываем параметр сортивки в адресную строку
  if (getCarsByQueriesIdRequest.match(action)) {
    const { sort } = getResultParams(store.getState());

    if (sort !== action.meta.sort) {
      urlQueryString.merge({ sort: action.meta.sort });
    }
  }

  // Ловим первый (чистый) запрос параметров
  if (getQueriesValuesSuccess.match(action)) {
    const {
      cars: {
        data: { filtersValues },
      },
    } = store.getState();

    const { filtersConfig } = config.getConfigObject();

    // Если фильтров в сторе нет значит это первый (чистый) запрос параметров
    if (!filtersValues && filtersConfig) {
      const query = urlQueryString.get();

      let formFiltersValues: { [key: string]: any } = {};

      // Нормализуем значения фильтров указанные в адресной строке
      Object.entries(filtersConfig).forEach(([name, value]) => {
        // Если в query указано значние фильтра
        if (name in query) {
          // Ищим возможные значения для фильтров
          const filterValues = action.payload.find(
            (filterPossibleValues) => filterPossibleValues.name === value.path
          );

          // Если возможные значения есть проверяем то, что указано в адресной строке
          if (filterValues) {
            if (typeGuards.isFilterValueDefault(filterValues)) {
              if (
                filterValues.values.some((filterValuesItem) => {
                  // для фильтра color поле value отсутвует, т.о. значение берем с поля "id"
                  const keyValue =
                    filterValuesItem.value === undefined ? 'id' : 'value';

                  // null значения оставляем как есть
                  // остальные приводим к строке т.к в query все "простые" значения - строки
                  const resolvedFilterValuesItemValue =
                    filterValuesItem[keyValue] === null
                      ? null
                      : filterValuesItem[keyValue].toString();

                  return resolvedFilterValuesItemValue === query[name];
                })
              ) {
                // Значение валидное
                formFiltersValues[name] = query[name];
              } else {
                // Значение не валидное, убираем указанный фильтр из адресной строки
                delete query[name];
                urlQueryString.set(query);
              }
            }

            if (
              typeGuards.isFilterValueRange(filterValues) &&
              Array.isArray(query[name])
            ) {
              const [min, max] = (query[name] as string[]).map(Number);

              if (
                min >= filterValues.minValue &&
                max <= filterValues.maxValue
              ) {
                formFiltersValues[name] = [min, max];
              } else if (
                min < filterValues.minValue &&
                max <= filterValues.maxValue
              ) {
                formFiltersValues[name] = [filterValues.minValue, max];
              } else if (
                max > filterValues.maxValue &&
                min >= filterValues.minValue
              ) {
                formFiltersValues[name] = [min, filterValues.maxValue];
              } else {
                formFiltersValues[name] = [
                  filterValues.minValue,
                  filterValues.maxValue,
                ];
              }

              query[name] = formFiltersValues[name];
              urlQueryString.set(query);
            }
          }
          // Если нет возможных значений сохраняем значение как есть
          else {
            formFiltersValues[name] = query[name];
          }
        }
      });

      // Приводим объект со значениями к виду пригодному для работы с rc-form
      // и обновляем эти поля формы в сторе
      store.dispatch(
        filtersUpdate(
          Object.entries(formFiltersValues).reduce<Fields>(
            (acc, [name, value]) => {
              acc[name] = { value };
              return acc;
            },
            {}
          ),
          {
            updateUrl: false,
          }
        )
      );

      // Подготавливаем данные для запроса в соответсвии с конфигурацией фильтров
      const resolvedFiltersFields = Object.keys(filtersConfig).reduce<{
        [key: string]: any;
      }>((acc, field) => {
        const filterConfig = filtersConfig[field];

        if (!filterConfig) return acc;

        const value = formFiltersValues[filterConfig.name];
        const resolvedValue = UiHelper.resolveFieldValue(filterConfig, value);

        if (resolvedValue !== undefined) {
          acc[filterConfig.id] = resolvedValue;
        }

        return acc;
      }, {});

      // Инициализируем запрос на получение отфильтрованного списка тачек
      store.dispatch(postQueries(resolvedFiltersFields));
    }
  }

  // Обновление фильтров в адресной строке при изменении их в сторе
  if (filtersUpdate.match(action)) {
    if (action.meta.updateUrl) {
      // Приводим к виду key: value
      const resolvedQuery = Object.entries(action.payload).reduce<{
        [key: string]: any;
      }>((acc, [name, { value }]) => {
        acc[name] = value;
        return acc;
      }, {});

      urlQueryString.merge(resolvedQuery);
    }
  }

  // Сброс фильтров в адресной строке
  if (filtersReset.match(action)) {
    const { filtersConfig } = config.getConfigObject();

    if (filtersConfig) {
      const query = urlQueryString.get();
      Object.keys(filtersConfig).forEach((name) => delete query[name]);
      urlQueryString.set(query);
    }
  }

  next(action);
};

export default urlMiddleware;
