import { createReducer as toolkitCreateReducer } from '@reduxjs/toolkit';
import { config, abortMap, UiHelper, urlQueryString } from '../../utils';
import { getRangesAsFiltersState } from '../../selectors';

import {
  getCarRequest,
  getCarSuccess,
  getCarError,
  postQueriesRequest,
  postQueriesSuccess,
  postQueriesError,
  getCarsByQueriesIdRequest,
  getCarsByQueriesIdSuccess,
  getCarsByQueriesIdError,
  getQueriesSummaryRequest,
  getQueriesSummarySuccess,
  getQueriesSummaryError,
  getQueriesValuesRequest,
  getQueriesValuesSuccess,
  getQueriesValuesError,
  filtersUpdate,
  filtersReset,
  rangesUpdate,
  setCar,
  clearCar,
} from './actions';

import { CarsState } from './types';

export default function createReducer() {
  const query = urlQueryString.get();

  const [sort, page, perPage] = config.get([
    'defaultSort',
    'defaultPage',
    'defaultPerPage',
  ]);

  const initialState: CarsState = {
    data: {
      sort: query.sort || sort,
      page,
      perPage,
    },
    loading: {
      getCar: false,
      postQueries: undefined,
      getQueriesSummary: undefined,
      getCarsByQueriesId: undefined,
      getQueriesValues: undefined,
    },
    error: {},
  };

  return toolkitCreateReducer<CarsState>(initialState, builder => {
    /**
     * Получение авто
     */

    builder.addCase(getCarRequest, state => {
      state.error.getCar = undefined;
      state.loading.getCar = true;
    });

    builder.addCase(getCarSuccess, (state, action) => {
      state.data.car = action.payload;
      state.loading.getCar = false;
    });

    builder.addCase(getCarError, (state, action) => {
      state.error.getCar = action.payload;
      state.loading.getCar = false;
    });

    /**
     * Установка авто
     */

    builder.addCase(setCar, (state, action) => {
      state.data.car = action.payload;
    });

    /**
     * Удаление авто
     */

    builder.addCase(clearCar, state => {
      state.data.car = undefined;
    });

    /**
     * Получение queries id на отфильтрованный список автомобилей
     */

    builder.addCase(postQueriesRequest, (state, action) => {
      abortMap.abort(state.loading.postQueries);
      state.error.postQueries = undefined;
      state.loading.postQueries = action.payload;
    });

    builder.addCase(postQueriesSuccess, (state, action) => {
      state.data.queriesId = action.payload.id;
      abortMap.clean(state.loading.postQueries);
      state.loading.postQueries = undefined;
    });

    builder.addCase(postQueriesError, (state, action) => {
      state.error.postQueries = action.payload;
      abortMap.clean(state.loading.postQueries);
      state.loading.postQueries = undefined;
    });

    /**
     * Получение сокращенных результатов поиска по queriesId
     */

    builder.addCase(getQueriesSummaryRequest, (state, action) => {
      abortMap.abort(state.loading.getQueriesSummary);
      state.error.getQueriesSummary = undefined;
      state.loading.getQueriesSummary = action.payload;
    });

    builder.addCase(getQueriesSummarySuccess, (state, action) => {
      state.data.count = action.payload[0]?.count || 0;
      abortMap.clean(state.loading.getQueriesSummary);
      state.loading.getQueriesSummary = undefined;
    });

    builder.addCase(getQueriesSummaryError, (state, action) => {
      state.error.getQueriesSummary = action.payload;
      abortMap.clean(state.loading.getQueriesSummary);
      state.loading.getQueriesSummary = undefined;
    });

    /**
     * Получение отфильтрованного списка автомобилей по queries id
     */

    builder.addCase(getCarsByQueriesIdRequest, (state, action) => {
      abortMap.abort(state.loading.getCarsByQueriesId);
      state.error.getCarsByQueriesId = undefined;
      state.loading.getCarsByQueriesId = action.payload;
    });

    builder.addCase(getCarsByQueriesIdSuccess, (state, action) => {
      state.data.cars =
        action.payload.page === 0
          ? action.payload.items // Сбрасываем cars, если загружена первая страница
          : [...(state.data.cars || []), ...action.payload.items];

      state.data.page = action.payload.page;
      state.data.sort = action.payload.sort;
      abortMap.clean(state.loading.getCarsByQueriesId);
      state.loading.getCarsByQueriesId = undefined;
    });

    builder.addCase(getCarsByQueriesIdError, (state, action) => {
      state.error.getCarsByQueriesId = action.payload;
      abortMap.clean(state.loading.getCarsByQueriesId);
      state.loading.getCarsByQueriesId = undefined;
    });

    /**
     * Получение возможных значений для фильтров
     */

    builder.addCase(getQueriesValuesRequest, (state, action) => {
      abortMap.abort(state.loading.getQueriesValues);
      state.error.getQueriesValues = undefined;
      state.loading.getQueriesValues = action.payload;
    });

    builder.addCase(getQueriesValuesSuccess, (state, action) => {
      // Выставляем ranges и value для range фильтров при первой загрузке фильтров
      // возможно эту логику нужно вынести в middleware.
      if (!state.data.filtersValues) {
        const uiHelper = new UiHelper({
          filters: action.payload,
        });

        const rangesFilters = uiHelper.getRangesFilters();
        const rangesValues = uiHelper.getRangesValues();

        if (rangesFilters && rangesValues) {
          // Выставляем диапазоны если их ещё нет
          state.data.ranges = {
            ...rangesFilters,
            ...state.data.ranges,
          };

          // Выставляем values если их ещё нет
          state.data.filtersState = {
            ...rangesValues,
            ...state.data.filtersState,
          };
        }
      }

      state.data.filtersValues = action.payload;

      abortMap.clean(state.loading.getQueriesValues);
      state.loading.getQueriesValues = undefined;
    });

    builder.addCase(getQueriesValuesError, (state, action) => {
      state.error.getQueriesValues = action.payload;
      abortMap.clean(state.loading.getQueriesValues);
      state.loading.getQueriesValues = undefined;
    });

    /**
     * Обновляем состояние фильтров
     */
    builder.addCase(filtersUpdate, (state, action) => {
      state.data.filtersState = {
        ...state.data.filtersState,
        ...action.payload,
      };
    });

    /**
     * Сброс параметров фильтрации
     */
    builder.addCase(filtersReset, state => {
      const rangesAsFiltersState = getRangesAsFiltersState(state);

      state.data.filtersState = {
        ...rangesAsFiltersState,
      };
    });

    /**
     * Обновляем состояние диапазонов для range фильтров
     */
    builder.addCase(rangesUpdate, (state, action) => {
      state.data.ranges = {
        ...state.data.ranges,
        ...action.payload,
      };
    });
  });
}
