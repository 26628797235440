import getDataCarsRelated from '../getDataCarsRelated';
import { createSelector } from '@reduxjs/toolkit';

import { config } from '../../utils';
import getCar from '../getCar';

export const defaultCountCarsRelated = 3;

/**
 * Получение похожих авто из redux
 */
const getCarsRelated = createSelector(
  getCar,
  getDataCarsRelated,
  (car, { carsRelated }) => {
    const { carsRelated: carsRelatedConfig } = config.getConfigObject();

    const count = carsRelatedConfig?.count || defaultCountCarsRelated;

    // фильтрация относительно существующего авто
    const carsRelatedFiltered = carsRelated?.filter(
      (carRelated) => carRelated.id !== car?.id
    );

    return carsRelatedFiltered?.splice(0, count);
  }
);

export default getCarsRelated;
