import { createSelector } from '@reduxjs/toolkit';
import getData from '../getData';

/**
 * Информация связанная с кол-ом автомобилей в списке
 */
const getCarsCount = createSelector(
  getData,
  ({ count = 0, cars, perPage = 0 }) => {
    const all = count;
    const loaded = cars?.length ?? 0;
    const sourceRest = all - loaded;
    const rest = sourceRest >= 0 ? sourceRest : 0;
    const nextPage = Math.min(perPage, rest);

    return {
      /** Всего автомобилей */
      all,
      /** Загружено автомобилей */
      loaded,
      /** Осталось загрузить автомобилей */
      rest,
      /** Следующая страница которую нужно загрузить. Отсчет с 1 */
      nextPage,
    };
  }
);

export default getCarsCount;
