import { connect, Connect } from 'react-redux';
import CarstockContext from './context';

const carstockConnect: Connect = (
  mapState?: any,
  mapActions?: any,
  mergeProps?: any,
  options?: any
) => {
  const contextedOptions = {
    ...options,
    context: CarstockContext,
  };

  return connect(mapState, mapActions, mergeProps, contextedOptions);
};

export default carstockConnect;
