import { FC } from 'react';
import { createForm, createFormField } from 'rc-form';
import carstockConnect from '../connect';
import { Fields } from '../store/cars/types';
import { State } from '../store';
// eslint-disable-next-line import/named
import { filtersUpdate as filtersUpdateAction } from '../store/cars/actions';

const withForm = (component: FC<any>) => {
  const RcFormWraped = createForm({
    mapPropsToFields({ filters = {} }: { filters: Fields }) {
      const preparedFields = Object.keys(filters).reduce<any>((acc, name) => {
        acc[name] = createFormField(filters[name]);
        return acc;
      }, {});

      return preparedFields;
    },
    onFieldsChange({ filtersUpdate }: { filtersUpdate: any }, fields: any) {
      filtersUpdate(fields);
    },
  })(component);

  const mapState = (state: State) => ({
    filters: state.cars.data.filtersState,
    ranges: state.cars.data.ranges,
  });

  const mapActions = {
    filtersUpdate: filtersUpdateAction,
  };

  return carstockConnect(mapState, mapActions)(RcFormWraped);
};

export default withForm;
