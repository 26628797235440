import { createSelector } from '@reduxjs/toolkit';
import getData from '../getData';

/**
 * Возвращает список возможных значений для фильтров
 */
const getFiltersValues = createSelector(
  getData,
  ({ filtersValues }) => filtersValues
);

export default getFiltersValues;
