import { createSelector } from '@reduxjs/toolkit';
import getLoading from '../getLoading';
import getData from '../getData';

/**
 * Возвращает объект статуса загрузки списка автомобилей
 */
const getIsPageLoading = createSelector(
  getData,
  getLoading,
  (data, loading) => ({
    isZeroPage:
      !data.cars ||
      (data.cars.length === 0 &&
        data.page === 0 &&
        !!loading.getCarsByQueriesId),
    isNotZeroPage:
      data.cars?.length !== 0 &&
      data.page !== undefined &&
      data.page >= 0 &&
      !!loading.getCarsByQueriesId,
  })
);

export default getIsPageLoading;
